export function escapehtml(str)
{
	if (str==null) { return ""; }
	return str.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
}

var bindvars = {};

function bindeval(value, element, codestr, globals)
{
	try {
		codestr = "return ("+codestr+");";
		// TODO: switch to using new Function('"use strict"; return ('+codstr+');') or something like that
		// and then call that function with .call(value) so that *this* is set correctly for use
		if (value instanceof Object) {
			for (const varname in value) {
				codestr = "let "+varname+" = "+JSON.stringify(value[varname])+"; "+codestr;
			}
		}

		// put values in the namespace for convenience!
		if (globals instanceof Object) {
			for (const varname in globals) {
				codestr = "let "+varname+" = "+JSON.stringify(globals[varname])+"; "+codestr;
			}
		}
		for (const varname in bindvars) {
			codestr = "let "+varname+" = "+JSON.stringify(bindvars[varname])+"; "+codestr;
		}
		// code execution like this is dangerous... keep checking that this is safe!
		//console.log("bindeval codestr =",codestr);
		let func = new Function(codestr);
		return func.call(value);
	}
	catch(e) {
		console.log("exception in evalled code: "+codestr);
		console.log(e.toString());
		return "";
	}
}

export function register(name,value) {
	bindvars[name]=value;
}
register('escape',escapehtml);

var bindformat_re = /<{([^}]+)}>/g;

function bindformat(value,suffix,element,globals)
{
	//var result;
	/*if ('bindexec' in element.dataset) {
		result = bindeval(value,element,element.dataset.bindexec,globals);
	}*/
	var target=null;
	var format = null;
	let safe = false;

	if ('bindformatsafe'+suffix in element.dataset) {
		format = element.dataset['bindformatsafe'+suffix];
		safe = true;
	}
	else if ('bindformat'+suffix in element.dataset) {
		format = element.dataset['bindformat'+suffix];
		safe = false; // don't change this! needed to prevent safe from staying true
	}
	if ('bindtarget'+suffix in element.dataset) {
		target = element.dataset['bindtarget'+suffix];
	}
	// DEBUG info
	//console.log("bindformat "+name+" target - "+target+" format - "+format);
	if (format) {
		var data = '', data_raw = null;
		data = format.replace(bindformat_re,
			function(match,codestring) {
				let result = bindeval(value,element,codestring,globals);
				data_raw = result;
				if (result === null || result ===undefined) { result = ""; }
				if (!safe) {
					// auto escape values destined for URL attributes
					return (target=="@href"||target=="@src")?encodeURIComponent(""+result):escapehtml(""+result);
				}
				else {
					return result;
				}
			});
		//console.log("format = "+format+" post-format = "+data);
		if (target) {
			if (target[0]=='@') {
				if (element instanceof HTMLOptionElement && target=="@selected") {
					if (data_raw) {
						element.setAttribute("selected","selected");
					}
				}
				else if (element instanceof HTMLInputElement && target=="@checked") {
					if (data_raw) {
						element.setAttribute("checked","checked");
					}
				}
				else {
					element.setAttribute(target.slice(1),data);
				}
			}
			else {
				target = element.querySelector(target);
				target.innerHTML = data;
			}
		}
		else {
			element.innerHTML = data;
		}
	}
}

function bindif(value,node,globals,depth)
{
	if ((node instanceof HTMLElement) && node.hasAttribute("data-bindif")) {
		if(!bindeval(value,node,node.dataset.bindif,globals)) {
			if (depth==0) {
				// if we're at depth 0, then we don't want to delete this node
				//  because it has data-bind on it. We may want to add a flag
				// to track if we're in a template because we really only want
				// to remove if we're in a template. TODO
				node.style.display = "none"; // override display and hide
			}
			else {
				node.parentNode.removeChild(node);
			}
			return false;
		}
		else if (depth==0) {
			node.style.display = null; // make visible by not overriding
		}
	}
	return true;
}

function bindprocesselement(value,node,globals,depth)
{
	//console.log("bindprocesselement",node);
	if (!bindif(value,node,globals,depth)) {
		return false;
	}
	if (!(value instanceof Array)) {
		if ((node.hasAttribute("data-bindformat") || node.hasAttribute("data-bindformatsafe"))) {
			bindformat(value,"",node,globals);
		}
		for (var i=0; node.hasAttribute("data-bindformat"+i) || node.hasAttribute("data-bindformatsafe"+i); i++) {
			bindformat(value,""+i,node,globals);
		}
	}
	return true;
}

function bindwith(value,prevvalue,node,globals,depth)
{
	let templatee, parentnode, insertbeforenode;
	let traverse_children = false;
	//console.log("bindwith",depth);
	if (node instanceof HTMLElement && node.hasAttribute("data-bindtemplate")) {
		let templateid = node.getAttribute("data-bindtemplate");
		templatee = document.getElementById(templateid);
		//console.log("looking for template at ",templateid)
		if (templatee == null || templatee==undefined) {
			console.log("binder - template not found at id "+templateid);
			return;
		}
		parentnode = node;
		insertbeforenode = null;

		// do this here
		// clear the existing elements of the array (if there are any)
		while (node.firstChild) {
			node.removeChild(node.firstChild);
		}
	}
	else if (node instanceof HTMLElement && node.hasAttribute("data-bindtemplateafter")) {
		let templateid = node.getAttribute("data-bindtemplateafter");
		templatee = document.getElementById(templateid);
		//console.log("looking for template at ",templateid)
		if (templatee == null || templatee==undefined) {
			console.log("binder - template not found at id "+templateid);
			return;
		}
		parentnode = node.parentNode;
		insertbeforenode = node.nextSibling;

		traverse_children = true;
	}
	else {
		//console.log("bindwith, but no template",node)
		templatee = null;
		parentnode = node.parentNode;
		insertbeforenode = node.nextSibling;
	}

	// if there is a template to attach, process the current node now
	if (templatee) {
		if (traverse_children) {
			// if the template is AFTER, we need to process nodes within (this is sort of a HACK, maybe find a more semantic way)
			bindtraverseelement(prevvalue,node,globals,depth);
		}
		else {
			// if it's a template AND an array, then make sure we process the current node with the
			// parent value (prevvalue) so that any of it's attributes are addressed
			bindprocesselement(prevvalue,node,globals,depth); // don't traverse if there are template children
		}
	}

	if (value instanceof Array) {
		//console.log("bindwith is array","clearing",node);
		// now create all the child elements
		value.forEach((v, index)=>{
			var newglobals = Object.assign({},globals);
			newglobals['loop'] = {
				"index":index,
				"first":index==0,
				"last":index==(value.length-1)
			};
			let newnodes;
			if (templatee) {
				//console.log("bindwith - istemplate, duplicating node");
				newnodes = templatee.content.cloneNode(true);
			}
			else if (index>0) {
				//console.log("bindwith - no template, cloning all children")
				newnodes = node.cloneNode(true);
			}
			else {
				// is first node
				newnodes = node;
			}
			bindtraverseelement(v,newnodes,newglobals,depth);
			//console.log("bindwith - adding newnodes",newnodes,"to",parentnode);
			parentnode.insertBefore(newnodes,insertbeforenode);
			//parentnode.appendChild(newnodes);
		});
	}
	else if (templatee) {
		// it's an object with a template! apply the properties to new nodes
		//console.log("bindwith - object with template");
		let newnodes = templatee.content.cloneNode(true);
		bindtraverseelement(value,newnodes,globals,depth);
		parentnode.insertBefore(newnodes,insertbeforenode);
	}
	else {
		// it's an object! and no template! apply the properties
		bindtraverseelement(value,node,globals,depth);
	}
}

function bindtraverseelement(value,node,globals,depth)
{
	//console.log("bindtraverse",node);
	let godeeper = true;
	if (node instanceof HTMLElement) { // we could be a template document fragment so test for this
		godeeper = bindprocesselement(value,node,globals,depth);
	}
	if (godeeper) {
		//console.log("going deeper on",node);
		let nexte = node.firstChild;
		while (nexte != null) {
			let cure = nexte;
			//console.log("deeper at",cure,depth);
			nexte = cure.nextSibling; // get the next sibling now in case we delete this node
			if (!bindif(value,cure,globals,depth+1)) {
				// don't traverse down this node if bindif is set
				continue;
			}
			if (cure instanceof HTMLElement && cure.hasAttribute("data-bind")) {
				// this is a bind element, skip and don't descend
			}
			else if (cure instanceof HTMLElement && cure.hasAttribute("data-bindwith")) {
				//console.log("bindwith",node.dataset.bindwith,"with template",templateid);
				if (value.hasOwnProperty(cure.dataset.bindwith)) {
					bindwith(value[cure.dataset.bindwith],value,cure,globals,depth+1);
				}
				else if (depth>0) {
					// if we don't have the property this item wants, just delete this entry
					node.removeChild(cure);
				}
				else {
					// not sure if we'll ever need this... but if depth==0 we don't want to delete the node because it is where the data-bind attribute is
					cure.innerHTML="";
				}
			}
			else if (cure instanceof HTMLElement && cure.hasAttribute("data-bindtemplate")) {
				bindwith(value,null,cure,globals,depth+1);
			}
			else {
				bindtraverseelement(value,cure,globals,depth+1);
			}
		}
	}
	else {
		//console.log("not going deeper on",node);
	}
}

export class Binder {
    constructor(name)
    {
        // Do we need this filter? // name = name.replace(/[-'"]/,"_");
        this.name = name;
        this.bindvars = {};
		this.value = null;
    }
    set(value)
    {
		let bindname = this.name;
		let bindvars = this.bindvars;
		this.value = value;
        let elist = document.querySelectorAll("[data-bind='"+this.name+"']");
		elist.forEach((e)=>{
			bindwith(value,null,e,bindvars,0);
		});
    }
	get()
	{
		return this.value;
	}
    clear()
    {
        let elist = document.querySelectorAll("[data-bind='"+this.name+"']");
        elist.forEach((e)=>{
            // remove all children
			while (e.childNodes.length>0) {
				e.removeChild(e.firstChild);
			}
        });
    }
}