import $ from 'jquery';

import * as tagslib from './tags';
import './tag-bible';
import './tag-url';
import './tag-pages';
window.tagslib = tagslib;

tagslib.add({name:'hashtag',
		re:/#([-A-Z0-9a-z_]{1,100})/,
		match:function(matchobj) {
			return [{
				text:matchobj[1].toLowerCase(),
				frompage:0, topage:0
			}];
		},
		link:function(tag) {
			return "/tag/"+encodeURIComponent(tag.text);
		},
		format:function(tag,origtextslice) {
			// if we have the original text, just use that
			// (it retains capitalization, we store just lowercase)
			if (origtextslice) { return origtextslice; }
			else { return "#"+tag.text; }
		}
	}
);

var token = null, tokendata = null;
export function save_token(t,td) { token=t; tokendata=td; }
export function scrollToTop()
{
	// it seems if we call ScrollToTop right after adding content... the
	// content isn't actually there yet... so we delay just a moment for it
	// to show up
	setTimeout(()=>{
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	},100);
}
export function mnfetch(url,options)
{
	if (token != null) {
		if (options === undefined) { options = {}; }
		if (!("headers" in options)) {
			options.headers = {}
		}
		options.headers["Authorization"] = "Bearer "+token;
	}
	let result = fetch(url,options);
	return result.then(function(response){
		if(response.status==403 || response.status==401) {
			// on forbidden, go to login tab
			// TODO: Test this!
			window.location = '/needlogin';
			return null;
		}
		else if (response.status==404) {
			return null;
		}
		else { return response.json(); }
	});
}

var template_callbacks = {};
var template_first_called = false;
export function ssrender_template_register(templatename,callback)
{
	template_callbacks[templatename] = callback;
	if (window.APP_TEMPLATE_NAME == templatename && !template_first_called) {
		callback();
		template_first_called = true;
	}
}

export function ssrender_fetch(url,options)
{
	if (options === undefined) { options = {}; }
	if (token != null) {
		if (!("headers" in options)) {
			options.headers = {}
		}
		options.headers["Authorization"] = "Bearer "+token;
	}

	// this is really naive...
	if (url.indexOf('?') != -1) {
		url += "&";
	}
	else {
		url += "?";
	}
	url += "accept=shtml";

	// setup loading page
	document.getElementById("content").innerHTML = '<div class="container-fluid navbarpad" style="text-align:center;"><i class="fas fa-5x fa-spinner fa-spin"></i></div>';
	scrollToTop();

	let result = fetch(url,options);
	return result.then(function(response){
		return new Promise((resolve,reject) => {
			var templatename = response.headers.get('X-Template-Name');
			var title = null;
			// Extract title here (if provided)
			if (response.headers.has('X-Title')) {
				title = response.headers.get('X-Title');
				title = decodeURIComponent(escape(title)); // attempt utf-8 decoding
			}
			response.text().then(function(text){
				document.getElementById("content").innerHTML = text;
				// set title here (if there is one, otherwise just use some sane default)
				if (title) {
					document.title = title + " - "+window.APP_TITLE;
				}
				else {
					document.title = window.APP_TITLE;
				}

				// call the template call back *after* we've added the HTML to the DOM
				if (templatename != null) {
					console.log("template name is",templatename);
					if (templatename in template_callbacks) {
						template_callbacks[templatename]();
					}
				}
				scrollToTop();
				resolve(text);
			});
		});
	});
}

