import * as tags from './tags';

tags.add({name:"pages",
		re:/\b[pP](\d{1,8})(?:-(\d{1,8}))?/,
		match:function(matchobj) {
			let f,t;
			f = parseInt(matchobj[1]);
			if (matchobj[2]) {
				t = parseInt(matchobj[2]);
				if (t<f) { t=f; }
			}
			else {
				t = f;
			}
			return [{
				frompage:f, topage:t
			}];
		},
        link:function(tag) { return null; },
		format:function(tag,origtextslice){
            if (origtextslice) {
			    return origtextslice;
            }
            else {
                return "p"+tag.frompage+((tag.topage>tag.frompage)?("-"+tag.topage):"");
            }
		}
});
