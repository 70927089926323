var tagslib = require('./tags');
tagslib.add({name:'url',
		re:/(?:(https?):\/\/)?((?:www\.)?(?:[-a-zA-Z0-9@:%_\+~#=]{2,256}\.){1,5}[a-z]{2,8}\b)([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
		match:function(matchobj) {
			var url_normalized = (matchobj[1]?matchobj[1]:'http')+"://"+matchobj[2]+matchobj[3];
			var topleveldomain = matchobj[2].substring(matchobj[2].length-3);
			if (matchobj[1]==null && !(topleveldomain=="com" || topleveldomain=="net" || topleveldomain=="org")) {
				// if it's not a well known top-level domain AND they didn't specify a full URL with scheme,
				// then ignore this url so that we don't accidentally match non-urls
				return [];
			}
			return [{
				text:url_normalized,
				frompage:0, topage:0
			}];
		},
		link:function(tag) {
			return tag.text;
		},
		format:function(tag,origtextslice) {
			// if we have the original text, just use that
			// (it retains capitalization, we store just lowercase)
			if (origtextslice) { return origtextslice; }
			else { return tag.text; }
		}
	}
);

