var routes = [];

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
function escapeRegExp(string) {
	return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function add(regex,cb)
{
	if (!(regex instanceof RegExp)) {
		let newregex = "^";
		let mobj = null;
		let replaceRe = /::/g;
		let lastmatch = 0;
		while ((mobj = replaceRe.exec(regex)) != null) {
			newregex += escapeRegExp(regex.substring(lastmatch,mobj.index));
			newregex += "([- :.,_=+%a-zA-Z0-9]+)";
			lastmatch = mobj.index + mobj[0].length;
		}
		newregex += escapeRegExp(regex.substring(lastmatch))+"$";
		//console.log("made new regex "+newregex);
		regex = new RegExp(newregex);
	}
    routes.push({"regex":regex,"cb":cb});
}

export function clear()
{
	routes = [];
}

export function getCurrentPath()
{
	return window.location.pathname;
}

function activatelink(newurl)
{
	console.log("activating for url "+newurl.toString());
	var mobj = null;

	for (var i=0; i<routes.length; i++) {
		//console.log("comparing",newurl.pathname,"with",routes[i].regex.toString());
		if ((mobj = routes[i].regex.exec(newurl.pathname)) != null) {
			break;
		}
	}
	if (mobj == null) { return false; }

	var args = [];
	for (var j=1; j<mobj.length; j++) {
		args.push(decodeURIComponent(mobj[j]));
	}

	var newpathhash = newurl.hash;

	if (newpathhash && newpathhash[0]=="#") {
		newpathhash = decodeURIComponent(newpathhash.substring(1));
	}
	args.push({url:newurl,hash:newpathhash});

	//console.log("calling callback with args="+args.toString()+" and hash="+newpathhash);
	// maybe we want to call this via setTimeout so it doesn't activate immediately?
	routes[i].cb.apply(null,args);
	return true;
}

function activatelink_withhistory(newurl)
{
	if (activatelink(newurl)) {
		if (history.pushState) {
			history.pushState(null,document.title,newurl.href);
		}
		return true;
	}
}

export function go(newurl)
{
	newurl = new URL(newurl,window.location.href);
	if (location.origin == newurl.origin) {
		if(activatelink_withhistory(newurl)) {
			return true;
		}
	}
	// some other site or unsupported link. reload!
	window.location = newurl.href;
	return false;
}

export function reload()
{
	// reload the current page (usually if something has changed like login status)
	// but don't change our history!
	activatelink(new URL(window.location.href));
}

if (history.pushState) {
	// history API exists!
	window.addEventListener("popstate",(evt)=>{
		activatelink(new URL(window.location.href));
	});
	document.body.addEventListener("click",(evt)=>{
		if (evt.altKey || evt.ctrlKey || evt.metaKey) {
			// ignore this click here - they've pushed a special button!
			return true;
		}

		var target = evt.target || evt.srcElement;
		// we need to go up the DOM tree until we find a link
		while (target != null && target.nodeName!="A") {
			target = target.parentNode;
		}
		if (target == null || target.nodeName != "A") {
			return true;
		}

		let href = target.getAttribute("href");
		if (href=="#") {
			//ignore this link
			return true;
		}
		// let's see if the link is relative
		let cururl = new URL(window.location.href);
		let newurl = new URL(href, window.location.href);
		if (cururl.origin != newurl.origin) {
			return true;
		}

		// it's relative! intercept
		if (activatelink_withhistory(newurl)) {
			evt.preventDefault();
		}
	});
}
else {
	// history API doesn't exist... just let links go as usual
}