export var types = [];
export function add(tagtype){ types.push(tagtype); }
export function format(tag,origtextslice)
{
	for(var i=0; i<types.length; i++) {
		if (types[i].name == tag.type) {
			return types[i].format(tag,origtextslice);
		}
	}
	return tag.text;
}

export function link(tag)
{
	for(var i=0; i<types.length; i++) {
		if (types[i].name == tag.type && 'link' in types[i] && types[i].link != null) {
			return types[i].link(tag);
		}
	}
	return null;
}

export function render(origtext, tags)
{
	console.log("tags.render - origtext - "+origtext);
	let origtextoffset = 0;
	if (tags!=null) {
		tags.slice(0).sort(function(a,b){return a.origtextstart-b.origtextstart;}).forEach(function(tag){
			let link = exports.link(tag);
			let origtextslice = origtext.substring(origtextoffset+tag.origtextstart,origtextoffset+tag.origtextstart+tag.origtextlen);
			let text = exports.format(tag,origtextslice);
			if (link) {
				text = '<a '+(link[0]!='#'?'target="_blank" ':'')+'href="'+link+'">'+text+'</a>';
			}
			console.log("tags.render - replace '"+origtext+"' with '"+text+"' at "+(origtextoffset+tag.origtextstart));
			origtext = origtext.substring(0,origtextoffset+tag.origtextstart)+text+origtext.substring(origtextoffset+tag.origtextstart+tag.origtextlen);
			origtextoffset += (text.length-tag.origtextlen);
		});
	}
	console.log("return - "+origtext);
	return origtext;
}

export function detect(origtext)
{
	var tags=[];
	for(var i=0; i<types.length; i++) {
		//console.log("detect tags for type="+types[i].name+" on: "+origtext);
		let m=types[i].re.exec(origtext);
		let origtextpos = 0;
		while (m!=null) {
			let mtags = types[i].match(m);
			// before committing to this tag, make sure that we didn't already
			// highlight a tag from this part of the text (no duplicates! no overlapping
			// tags! do the tags in order)
			if(!tags.some(
					(tag)=>
						(tag.origtextstart<=(origtextpos+m.index))
						&& ((tag.origtextstart+tag.origtextlen)>=(origtextpos+m.index+m[0].length))
					)) {
				for (var j=0; j<mtags.length; j++) {
					// set the type on each tag (so the plugin doesn't have to)
					mtags[j].type=types[i].name;
					// validate that topage is >= frompage
					if (mtags[j].topage) {
						mtags[j].topage = Math.max(mtags[j].frompage,mtags[j].topage);
					}
					// don't overwrite values, but fix them up or set defaults
					if (!('origtextstart' in mtags[j])) {
						mtags[j].origtextstart=0;
					}
					mtags[j].origtextstart += origtextpos+m.index
					if (!('origtextlen' in mtags[j])) {
						mtags[j].origtextlen = m[0].length
					}
				}
				tags = tags.concat(mtags);
			}
			origtextpos = origtextpos+m.index+m[0].length;
			//console.log("detect tags 2nd on: "+origtext);
			m=types[i].re.exec(origtext.substring(origtextpos));
		}
	}
	return tags;
}

