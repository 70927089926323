import 'bootstrap';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
//import '@fortawesome/fontawesome-free/js/brands';

import $ from 'jquery';

import { Binder } from './binder';
import {save_token,ssrender_fetch,mnfetch} from './common';
var router = require('./router');
import './notenew';

let lastsearch = null;
function runsearch(notebookslug,searchstr)
{
	if (searchstr == null) { return; }
	console.log("searching for "+searchstr);
	// optimize: no need to try to search again if we already queried this
	/*if (searchstr == lastsearch) {
		return;
	}*/
	lastsearch = searchstr;
	// do search
	let tags = tagslib.detect(searchstr);
	ssrender_fetch(
		"/nb/"+encodeURIComponent(notebookslug)+"/search",{
		method:'POST',headers:{"Content-Type":'application/json'},
		body:JSON.stringify({'query':searchstr,'tags':tags})
	});
}

$(document).ready(function() {
	document.onkeyup = function(e) {
		if (e.altKey && e.which == 65) {
			router.go("/nb/"+encodeURIComponent(current_notebook.get().slug)+"/note/new");
			e.preventDefault();
		}
	};

	// maybe we could put some kind of delay in here to wait for a couple of keystrokes and then
	// a short pause.
	let searchtimeoutid = null;
	$('#search').on('input',function(e){
		let searchstr = $(this).val().trim();

		if (searchstr.indexOf(" ") == -1) {
			// just a single word so far so try to auto complete the string
			mnfetch("/nb/"+encodeURIComponent(current_notebook.get().slug)+"/tagcomplete?text="+encodeURIComponent(searchstr)).then((data)=>{
				var dataliste = $('#searchtagslist');
				var datalistoptions = dataliste.find("option");
				datalistoptions.each(function(idxunused,element){
					let index = data['results'].indexOf($(element).val());
					if(-1 == index) {
						$(element).remove();
					}
					else {
						data['results'].splice(index,1);
					} 
				});
				//dataliste.empty(); // this causes a slight flicker... perhaps we can just filter the existing list?
				data['results'].forEach((tag)=>{
					dataliste.append("<option value=\""+encodeURIComponent(tag)+"\"></option>");
				});
			});
		}
		else {
			// the search string has a space in it: clear the auto complete list
			$('#searchtaglist').empty();
		}
	});
	function searchformsubmit(){
		// If the toggler is active, hide the window (disabling this for now since there is no collapse)
		//if ($('#navbarResponsive').hasClass("show")) { $('#navbarResponsive').collapse('hide'); }
		let searchstr = $('#search').val();
		// don't issue an empty search
		if (searchstr.length == 0) { return; }
		router.go("/nb/"+encodeURIComponent(current_notebook.get().slug)+'/search/'+encodeURIComponent(searchstr));
	}
	$('#searchsubmit').on('click',searchformsubmit);
	$('#searchform').on('submit',function(e){
		e.preventDefault();
		searchformsubmit();
	});

	window.current_notebook = new Binder("current_notebook");
	const DEFAULT_NOTEBOOK_VALUES = {"slug":null,"display_name":null,"is_public":true,"can_write":false,is_admin:false};

	function load_notebook_info(notebook)
	{
		if (notebook == null) {
			console.log("clearing current notebook values...");
			current_notebook.set(DEFAULT_NOTEBOOK_VALUES);
		}
		else {
			console.log("querying for notebook info for",notebook);
			// in case we need to use it now in a callback (like runsearch)...
			let temp_notebook = Object.assign({},DEFAULT_NOTEBOOK_VALUES);
			temp_notebook.slug = notebook;
			current_notebook.set(temp_notebook);
			// now get the rest of the data
			mnfetch("/nb/"+notebook+"/info").then((data)=>{
				console.log("got notebook info!",data);
				current_notebook.set(data);
			}); // TODO: we should handle when this fails for some reason
		}
	}

	function reload_notebook_info()
	{
		load_notebook_info(current_notebook.get().slug);
	}

	function update_notebook_info(notebook)
	{
		if (current_notebook.get() == null || notebook != current_notebook.get().slug) {
			load_notebook_info(notebook);
		}
	}
	if (window.MENSNOTA_CURRENT_NOTEBOOK) {
		current_notebook.set(window.MENSNOTA_CURRENT_NOTEBOOK);
	}
	else {
		update_notebook_info(null);
	}

	router.add("/nb/::/search/::",(notebook,query)=>{
		runsearch(notebook,query);
		update_notebook_info(notebook);
	});

	router.add("/",()=>{
		ssrender_fetch("/").then(()=>{
			update_notebook_info(null);
		});
	});
	router.add("/nb/::/",(notebook,urldata)=>{
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});
	});
	router.add("/nb/::/tag/::",(notebook,tagname,urldata)=>{
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});		
	});
	router.add("/nb/::/note/::",(notebook,noteid,urldata)=>{
		// just copy the current URL through to the server (including search params).
		// the server will validate everything!
		// this also handles the new page if noteid == "new"
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});		
	});
	router.add("/nb/::/note/::/edit",(notebook,noteid,urldata)=>{
		// just copy the current URL through to the server (including search params).
		// the server will validate everything!
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});		
	});
	router.add("/nb/::/timeline",(notebook,urldata)=>{
		// just copy the current URL through to the server (including search params).
		// the server will validate everything!
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});		
	});
	router.add("/nb/::/tags",(notebook,urldata)=>{
		// just copy the current URL through to the server (including search params).
		// the server will validate everything!
		ssrender_fetch(urldata.url.href).then(()=>{
			update_notebook_info(notebook);
		});		
	});
	var userinfo = new Binder("userinfo");
	userinfo.set({"name":null,"email":null,"picture":null});

	var authigator = authigator_init(AUTHIGATOR_CONFIG,function(data){
		if (data.result == "success") {
            userinfo.set(data.userinfo);
			save_token(data.token,data.data);

			// reload notebook info so that the permissions are updated for the now logged in user.
			reload_notebook_info();

			// FIXME: this is causing a page to reload every time... how can we
			// detect when the browser has brought this up the first time
			// versus the user logged in?
			router.reload();
		}
		else {
			console.log("error!")
		}
	});
	window.authigator = authigator;

	router.add("/logout", ()=>{
		userinfo.set({"name":null,"email":null,"picture":null});
		save_token(null,null); // clear token before invoking any other commands
		update_notebook_info(null);
		authigator.logout();
	});

	// this is a workaround to cover that we search is a client-side job. If a GET query tried
	// to trigger the search, we instead redirect it here to trigger the POST.
	// do this last (after all other initialization is complete)
	if(window.mensnota_runsearch) {
		runsearch(window.mensnota_runsearch_notebookslug,window.mensnota_runsearch);
		window.mensnota_runsearch = null;
	}
});
