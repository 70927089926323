import $ from 'jquery';
import * as tagslib from './tags';
import {mnfetch, ssrender_template_register} from './common';
import {Binder} from './binder';
var router = require('./router');

var model = null;

var lasttags = [];
function updatetagscb(){
    console.log("updatetagscb "+this.value);
    var tags = tagslib.detect(this.value);
    checktagexists(tags); // filter to put is_new on the tags array
    model.set(tags);
    console.log(tags);
    lasttags = tags;
}
function updatetags()
{
    // convenience function so we can use this in the above event handler
    updatetagscb.call(document.getElementById("addtext"));
}
var tagexists = {};
function checktagexists(tags)
{
    let tagstoquery=[];
    for(let i=0; i<tags.length; i++) {
        if (tags[i].type == "hashtag") {
            if (!tagexists.hasOwnProperty(tags[i].text)) {
                // it's a tag not in our cache. we need to query it
                tagstoquery.push(tags[i].text);
                tagexists[tags[i].text] = null;
            }
            tags[i].is_new = (tagexists[tags[i].text] === false); // use === because there are 3 values, true, false, and null means being queried
        }
        else {
            tags[i].is_new = false; // just put this on all nodes
        }
    }
    if (tagstoquery.length>0) {
        mnfetch('/nb/'+encodeURIComponent(current_notebook.get().slug)+'/tagsexist',{
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify({'tags':tagstoquery})
        }).then(function(data){
            console.log("got back updated tags from tagsexist")
            if (data==null) { return; }
            for (let i=0; i<data.results.length; i++) {
                tagexists[data.results[i].tag] = data.results[i].exists;
            }
            updatetags(); // this will trigger an update and redraw
        });
    }
}
function addsubmit(e) {
    e.preventDefault();
    let noteid = $('#noteid').val();

    if (current_notebook.get().slug == null) {
        console.log("hmm... current_notebook is null? not submitting!");
    }
    else if(noteid=="") {
        $('#addtext').attr('disabled',true);
        mnfetch('/nb/'+encodeURIComponent(current_notebook.get().slug)+'/note/new',{
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify({'text':$('#addtext').val(),'title':$('#addtitle').val(),'tags':lasttags,'tagnote':$('#addtagnote').val()})
        }).then(function(data){
            if (data==null) { return; }
            $('#addtext').attr('disabled',false).val('');
            $('#addtitle').val('');
            $('#addtagnote').val('');
            lasttags = [];
            //cachedirty();
            router.go('/nb/'+encodeURIComponent(current_notebook.get().slug)+'/note/'+encodeURIComponent(data['id']));
        });
    }
    else {
		mnfetch('/nb/'+encodeURIComponent(current_notebook.get().slug)+'/note/'+noteid,{
			method:'PATCH',headers:{"Content-Type":'application/json'},
			body:JSON.stringify({'text':$('#addtext').val(),'title':$('#addtitle').val(),'tags':lasttags})
		}).then(function(response){
			$('#addtext').attr('disabled',false).val('');
			$('#addtitle').val('');
			lasttags = [];
			//cachedirty();
			router.go('/nb/'+encodeURIComponent(current_notebook.get().slug)+'/note/'+encodeURIComponent(noteid));
		});
    }
}

ssrender_template_register("notenew",()=>{
    model = new Binder("notetags");
    updatetags();
    $('#addtext').on('input propertychange',updatetagscb);
    $('#addsubmit').on('click',addsubmit);
    $('#addtext').on('keyup',function(e){
        if (e.ctrlKey && e.which == 13) { addsubmit(e); }
    });
    $('#addtext').focus();
});